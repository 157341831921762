import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

import ReactPlayer from "react-player";

import colors from "assets/theme/base/colors";
import icon from "assets/logos/favicon.png";

export default function VideoPlaying({
  videos,
  playing,
  setPlaying,
  showPlayList,
  setShowPlayList,
  currentVideo,
  setCurrentVideo,
  toggleVideoPlaying,
}) {
  const [progress, setProgress] = useState(0);
  const { data } = videos;

  const handlePrev = () => {
    data.forEach(({ url }, index) => {
      if (url === currentVideo) {
        const videoData = data[index - 1] || data[data.length - 1];
        setPlaying(false);
        setProgress(0);
        setCurrentVideo(videoData.url);
      }
    });
  };

  const handleNext = () => {
    data.forEach(({ url }, index) => {
      if (url === currentVideo) {
        const videoData = data[index + 1] || data[0];
        setPlaying(false);
        setProgress(0);
        setCurrentVideo(videoData.url);
      }
    });
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox
        height="100%"
        bgColor="info"
        variant="gradient"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <SoftBox height="100%" sx={{ position: "relative" }}>
          <SoftBox
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: "absolute",
              px: "0.5vw",
              py: "1vh",
            }}
          >
            <Icon
              fontSize="large"
              sx={{
                color: colors.white.main,
                cursor: "pointer",
                zIndex: 999999,
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
              onClick={() => {
                setShowPlayList(!showPlayList);
                setPlaying(false);
              }}
            >
              arrow_back
            </Icon>
            <Icon
              fontSize="large"
              sx={{
                color: colors.white.main,
                cursor: "pointer",
                zIndex: 999999,
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
              onClick={toggleVideoPlaying}
            >
              close
            </Icon>
          </SoftBox>
          <ReactPlayer
            controls
            playing={playing}
            url={currentVideo}
            width="100%"
            height="100%"
            onReady={() => setPlaying(true)}
            onProgress={({ played }) => playing && setProgress(played * 100)}
            onEnded={handleNext}
          />
        </SoftBox>
        <SoftBox p="1vw" display="flex" justifyContent="flex-start">
          <SoftBox
            height="75px"
            width="75px"
            bgColor="dark"
            variant="gradient"
            borderRadius="10px"
            mr="1vw"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={icon} alt="CTrading Icon" height="40px" width="40px" />
          </SoftBox>
          <SoftBox
            width="50%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <SoftTypography fontWeight="bold" fontSize="1.5rem" color="white">
              Deep Focus
            </SoftTypography>
            <SoftBox display="flex" alignItems="center" gap={1}>
              <SoftBox>
                <SoftBadge
                  badgeContent={
                    <SoftTypography
                      fontWeight="bold"
                      fontSize="0.6rem"
                      color="white"
                    >
                      CTrading Play List
                    </SoftTypography>
                  }
                  size="xs"
                  container
                />
              </SoftBox>
              <SoftTypography variant="overline" color="white">
                Crypto
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox
            px="3vw"
            width="50%"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={4}
          >
            <SoftBox display="flex" alignItems="center" gap={2}>
              <Icon
                sx={{
                  color: colors.white.main,
                  transform: "scale(2)",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(2.2)",
                  },
                }}
                onClick={handlePrev}
              >
                skip_previous
              </Icon>
              <Icon
                sx={{
                  color: colors.white.main,
                  transform: "scale(2)",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(2.2)",
                  },
                }}
                onClick={handleNext}
              >
                skip_next
              </Icon>
            </SoftBox>
            <SoftBox sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                variant="determinate"
                value={progress}
                size="4rem"
                sx={{ color: colors.white.main }}
              />
              <SoftBox
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setPlaying(!playing)}
              >
                <Icon
                  sx={{
                    color: colors.white.main,
                    transform: "scale(3)",
                    cursor: "pointer",
                    "&:hover": {
                      transform: "scale(3.2)",
                    },
                  }}
                >
                  {playing ? "pause" : "play_circle"}
                </Icon>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}
